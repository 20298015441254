<template>
  <v-container
    class="fill-height"
    max-width="374"
    fluid
  >
    <v-row
      v-if="USER_SYSTEMS.length"
      class="d-flex flex-column"
      justify="center"
    >
      <v-col
        v-for="(system) in USER_SYSTEMS"
        :key="system.value"
        @click="openSystem(system)"
      >
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-img contain width="150" height="150" :src="loadImage(system.image_path)"></v-img>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="d-flex flex-column"
      justify="center"
    >
      <v-col>
        <h3>No Assigned Systems. Please contact system administrator.</h3>
      </v-col>
    </v-row>
    <v-row v-if="IS_SYS_ADMIN">
      <v-col cols="12" class="d-flex justify-center align-center" v-if="$vuetify.breakpoint.mobile">
        <v-btn
          v-text="'Users'"
          @click="dialogs.user = true"
        ></v-btn>
      </v-col>
      <v-col cols="12">
        <v-dialog v-model="dialogs.user">
          <Users v-if="dialogs.user" @closeDialog="closeDialog"></Users>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import Users from '@/components/Users.vue'

  export default {
    data: () => ({
      dialogs: {
        user: false,
      },
    }),
    components: {
      Users,
    },
    computed: {
      ...mapGetters([
        'USER',
        'USER_SYSTEMS',
        'IS_SYS_ADMIN',
      ]),
    },
    methods: {
      openSystem(system) {
        // window.open(system.fe_url + `?username=${this.USER.username}&password=${this.USER.password}`, '_blank')
        // window.open(system.fe_url + `?token=${system.access_token}`, '_blank')
        window.open(system.fe_url + `?username=${this.USER.username}&password=${this.USER.password}${typeof system.access_token !== 'undefined' ? '&token=' + system.access_token : ''}`, '_blank')
      },
      loadImage(image_path) {
        return `${process.env.VUE_APP_API_HOST}/loadImage?imagePath=${image_path}`
      },
      closeDialog(val) {
        this.dialogs.user = val
      },
      listener(e) {
        if (e.ctrlKey && e.altKey && e.shiftKey && e.code === 'KeyU' && this.IS_SYS_ADMIN) {
          this.dialogs.user = true
        }
      }
    },
    mounted() {
      this.$store.dispatch('getUserSystems', {
        params: {
          user_id: this.USER.id
        }
      }).then(async response => {
        if (localStorage.getItem('systems_logged_in') === '0') {
          for await (let system of this.USER_SYSTEMS) {
            if (system.auto_login) {
              axios.post(`${system.be_url}/credentials/login`, {
                username: this.USER.username,
                password: atob(this.USER.password.split('%2B').join('+').split('%2F').join('/').split('%3D').join('=')),
              }).then(response => {
                system.access_token = btoa(response.data.access_token).split('+').join('%2B').split('/').join('%2F').split('=').join('%3D')
              })
            }
          }

          localStorage.setItem('systems_logged_in', '1')
        }

        this.loaded = true
      }).catch(error => {

      })

      document.addEventListener('keydown', this.listener)
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.listener)
    },
  }
</script>
