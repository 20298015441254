<template>
  <v-app>
    <v-main>
      <span
      v-if="$route.name == 'system-selection'"
      style="position:absolute;right: 0; padding:10px; z-index: 2; cursor: pointer;" 
      @click="logout()" 
      >
      Logout</span>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods:{
    logout(){
      this.$store.dispatch('logout').then((res)=>{
        if(res){
          this.$router.push({name:'home'})
        }
      });
    }
  }
};
</script>
