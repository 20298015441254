import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import api from '../plugins/api'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  plugins: [
    vuexLocal.plugin,
  ],
  state: {
    user: null,
    users: [],
    systems: [],
    user_systems: [],
    is_sys_admin: 0,
    departments: [],
  },
  getters: {
    USER: (state) => state.user,
    USERS: (state) => state.users,
    SYSTEMS: (state) => state.systems,
    USER_SYSTEMS: (state) => state.user_systems,
    IS_SYS_ADMIN: (state) => state.is_sys_admin,
    DEPARTMENTS: (state) => state.departments,
  },
  mutations: {
    USER_UPDATE: (state, payload) => state.user = payload,
    USERS_UPDATE: (state, payload) => state.users = payload,
    SYSTEMS_UPDATE: (state, payload) => state.systems = payload,
    USER_SYSTEMS_UPDATE: (state, payload) => state.user_systems = payload,
    IS_SYS_ADMIN_UPDATE: (state, payload) => state.is_sys_admin = payload,
    DEPARTMENTS_UPDATE: (state, payload) => state.departments = payload,
  },
  actions: {
    login({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        api.post('/login', payload).then(response => {
          response.data.password = btoa(payload.password).split('+').join('%2B').split('/').join('%2F').split('=').join('%3D')
          commit('USER_UPDATE', response.data)
          localStorage.setItem('landing_token', response.data.access_token)
          localStorage.setItem('systems_logged_in', '0')
          dispatch('getIsSysAdmin', payload)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    processUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url_path = `/api/user${payload.url ?? ''}`

        api[payload.method](url_path, payload).then(response => {
          commit('USERS_UPDATE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUserSystems({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('/api/user-system', payload).then(response => {
          commit('USER_SYSTEMS_UPDATE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getIsSysAdmin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        api.get('/api/is-sys-admin', payload).then(response => {
          commit('IS_SYS_ADMIN_UPDATE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDepartments({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let url_path = `/api/department${payload.url ?? ''}`

        api.get(url_path, payload).then(response => {
          commit('DEPARTMENTS_UPDATE', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        api.post('/api/logout').then(({ status }) => {
            if (status == 200) {
              localStorage.removeItem("landing_token");
              resolve(true)
            }
            commit()
          })
          .catch((error) => {
            reject(error)
          });
      });
    },
  },
})
