import axios from 'axios';
import router from '../router';

const URL = process.env.VUE_APP_API_HOST;
const instance = axios.create({
  baseURL: URL,
  withCredentials: false,
  maxContentLength: Infinity,
  maxBodyLength: Infinity
});

instance.interceptors.request.use((request) => {
  request.headers['Accept'] = 'application/json,text/html';
  request.headers['Content-Type'] = 'application/json,image/apng';
  request.headers['Authorization'] = `Bearer ${localStorage.getItem('landing_token')}`;
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      localStorage.removeItem('landing_token')
      localStorage.setItem('systems_logged_in', 0)

      router.push('/')
    }
    return Promise.reject(error);
  }
);

export default instance;
